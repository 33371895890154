import React, { useContext, useEffect } from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import Div100vh from "react-div-100vh";

// Hooks
import { useMediaQuery } from "../components/hooks/useMediaQuery";

// Previews
import { withPreview } from "gatsby-source-prismic";

// Context
import { PageBackgroundColor } from "../components/context/page-background-color";
import { PageType } from "../components/context/page-type";

// Components
import { HomepageGallery } from "../components/images/homepage-gallery";
import { MobileHomepageGallery } from "../components/images/mobile-homepage-gallery";

const PageContainer = styled.div`
  position: relative;

  width: 100%;
  height: 100%;

  overflow: hidden;
`;

const Index = ({ data }) => {
  let isDesktop = useMediaQuery("(min-width: 768px)");
  const [pageBackgroundColor, setPageBackgroundColor] = useContext(
    PageBackgroundColor
  );
  const [pageType, setPageType] = useContext(PageType);

  useEffect(() => {
    setPageBackgroundColor(`#FFFDFA`);
  }, [data]);

  useEffect(() => {
    setPageType(`Home`);
  }, [data]);

  return (
    <Div100vh>
      <PageContainer>
        {isDesktop ? (
          <HomepageGallery images={data.prismicHomepage.data.body} />
        ) : (
          <MobileHomepageGallery images={data.prismicHomepage.data.body2} />
        )}
      </PageContainer>
    </Div100vh>
  );
};

export default withPreview(Index);

export const query = graphql`
  {
    prismicHomepage {
      _previewable
      data {
        body {
          ... on PrismicHomepageBodySingleImage {
            id
            slice_type
            primary {
              image {
                alt
                fluid(imgixParams: { ar: "1310:870", fit: "crop" }) {
                  srcWebp
                  srcSetWebp
                }
                dimensions {
                  height
                  width
                }
              }
            }
          }
          ... on PrismicHomepageBodyTwoImages {
            id
            slice_type
            primary {
              image_left {
                alt
                fluid(
                  imgixParams: { ar: "600:870", fit: "crop" }
                  srcSetBreakpoints: [200, 600, 800, 900, 1200]
                ) {
                  srcWebp
                  srcSetWebp
                }
                dimensions {
                  height
                  width
                }
              }
              image_right {
                alt
                fluid(
                  imgixParams: { ar: "600:870", fit: "crop" }
                  srcSetBreakpoints: [200, 600, 800, 900, 1200]
                ) {
                  srcWebp
                  srcSetWebp
                }
                dimensions {
                  height
                  width
                }
              }
            }
          }
        }
        body2 {
          ... on PrismicHomepageBody2SingleImage {
            id
            slice_type
            primary {
              image {
                alt
                fluid {
                  srcWebp
                  srcSetWebp
                }
                dimensions {
                  height
                  width
                }
              }
            }
          }
        }
      }
    }
  }
`;

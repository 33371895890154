import React, { useContext, useEffect } from "react";
import { withUnpublishedPreview } from "gatsby-source-prismic";
import styled from "styled-components";
import Div100vh from "react-div-100vh";

// Context
import { PageBackgroundColor } from "../components/context/page-background-color";
import { PageType } from "../components/context/page-type";

// Pages
import Index from "./index";
import Projects from "./projects";

// Templates
import OverviewPage from "../templates/overview-page";
import Project from "../templates/project";
import TextPages from "../templates/text-pages";

const PageContainer = styled.div`
  position: relative;

  width: 100%;
  height: 100%;

  overflow: hidden;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  & h1 {
    font-size: 16px;
    line-height: 34px;
    letter-spacing: 0.15em;

    text-align: center;
  }
`;

const NotFoundPage = () => {
  const [pageType, setPageType] = useContext(PageType);
  const [pageBackgroundColor, setPageBackgroundColor] = useContext(
    PageBackgroundColor
  );
  useEffect(() => {
    setPageBackgroundColor(`#F8F1E8`);
    setPageType(`Home`);
  }, []);

  return (
    <Div100vh>
      <PageContainer>
        <h1 className="uppercase soehne-light">Page not found</h1>
      </PageContainer>
    </Div100vh>
  );
};

// If an unpublished `page` document is previewed, PageTemplate will be rendered.
export default withUnpublishedPreview(NotFoundPage, {
  templateMap: {
    homepage: Index,
    projects: Projects,
    project: Project,
    overview_section: OverviewPage,
    text_pages: TextPages,
  },
});

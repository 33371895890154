import React, { useEffect, useContext, useState } from "react";
import { graphql, Link } from "gatsby";
import styled from "styled-components";

// Previews
import { withPreview } from "gatsby-source-prismic";

// Context
import { PageBackgroundColor } from "../components/context/page-background-color";
import { PageType } from "../components/context/page-type";

// Icons
import { Toggle } from "../components/icons/menu-toggle";

const PageContainer = styled.div`
  position: relative;

  width: 100%;
  padding: 220px 30px 130px 30px;

  & .text-container {
    max-width: 880px;
    margin: 0 auto;

    & h3 {
      font-family: "soehne-breit-web-buch", "Helvetica Neue", "Lucida Grande",
        sans-serif;

      font-size: 10px;
      line-height: 28px;
      letter-spacing: 0.15em;

      margin: 1em 0 0 0;

      & + p {
        margin: 0 0 1em 0;
      }
    }

    & p:empty {
      display: block;
      height: 1em;
    }
  }
`;

const Header = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  z-index: 100;

  padding: 25px 40px;

  & h1 {
    font-size: 16px;
    line-height: 28px;

    letter-spacing: 0.02em;

    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
    text-rendering: optimizeLegibility !important;
    -webkit-font-smoothing: antialiased !important;

    @media (max-width: 1280px) {
      font-size: 15px;
      line-height: 25px;
    }
  }

  & a {
    display: block;

    width: 13px;
    height: 13px;

    margin: 0 0 0 auto;
  }

  & svg {
    transition: 300ms fill ease;
  }

  @media (max-width: 767px) {
    padding: 25px 30px;
  }
`;

const TextPages = ({ data }) => {
  const [pageType, setPageType] = useContext(PageType);
  const [pageBackgroundColor, setPageBackgroundColor] = useContext(
    PageBackgroundColor
  );

  useEffect(() => {
    setPageType(`hide-nav`);
    setPageBackgroundColor(`#F8F1E8`);
  }, []);

  return (
    <PageContainer>
      <Header>
        <div className="title">
          <div
            className=""
            dangerouslySetInnerHTML={{
              __html: data.prismicTextPages.data.title.html,
            }}
          />
        </div>

        <div className="back-button">
          <Link
            to={`/studio`}
            state={{
              slideId: { section: "contact", id: data.prismicContact.id },
            }}
          >
            <Toggle isOpen={true} fill={`#000`} />
          </Link>
        </div>
      </Header>

      <div
        className="text-container"
        dangerouslySetInnerHTML={{
          __html: data.prismicTextPages.data.text.html,
        }}
      />
    </PageContainer>
  );
};

export default withPreview(TextPages);

export const query = graphql`
  query TextPage($uid: String!) {
    prismicTextPages(uid: { eq: $uid }) {
      data {
        title {
          html
        }
        text {
          html
        }
      }
    }
    prismicContact {
      id
    }
  }
`;

import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { Fade } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import { useMeasure } from "react-use";

// Utils
import { ImageOrientation } from "../utils/image-orientation";

const GalleryContainer = styled.div`
  position: relative;

  width: 100%;
  height: 100%;
  max-height: 100%;

  padding: 75px 40px;

  @media (max-width: 767px) {
    padding: 65px 0 55px 0;
  }

  & > div,
  & .react-slideshow-container,
  & .react-slideshow-fade-wrapper,
  & .react-slideshow-fade-images-wrap,
  & .react-slideshow-fade-images-wrap > div,
  & .slider-wrapper,
  & .image-gallery,
  & .slider,
  & .slide {
    height: 100%;
  }

  & .carousel.carousel-slider .control-arrow {
    padding: 0;
    width: 50%;
  }
`;

const Slide = styled.div`
  position: relative;
  width: 100%;
  height: 100%;

  &.max-width {
    max-width: 1400px;
    margin: 0 auto;
  }

  & .aspect-ratio-box {
    height: 100%;
    overflow: hidden;

    position: relative;

    max-height: calc(100vh - 150px);

    & .aspect-ratio-box-inside {
      width: 100%;
      height: 100%;

      max-height: calc(100vh - 150px);
    }
  }

  &.image-slide {
    height: 100%;
    padding: 0 40px;
  }

  & .flex {
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;

    width: 100%;
    height: 100%;

    & .two-images-single-image-container {
      position: relative;
      flex: 50%;
      max-width: 50%;

      &:first-of-type {
        & img {
          object-position: center left;
        }
      }

      &:last-of-type {
        & img {
          object-position: center right;
        }
      }

      & img {
        height: calc(100% - 29px);
      }
    }
  }

  &.two-images-container {
    & .aspect-ratio-box {
      top: 50%;
      transform: translateY(-50%);

      width: ${props => props.width}px;
      height: ${props => props.height}px;
      margin: 0 auto;
    }
  }

  & .two-images {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    align-items: center;

    & .image-container {
      position: relative;
      width: 100%;
      height: 100%;
    }

    & .spacer {
      flex: 8.333%;
      max-width: 8.333%;
      display: none;
    }

    &.small-image-right {
      & .image-left {
        grid-column: 1 / 7;

        & img {
          object-fit: cover;
        }
      }

      & .image-right {
        grid-column: 8 / 13;

        padding: 75px 0 0 0;

        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        & .image-container {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;

          & img {
            max-height: calc(100% - 150px);
          }
        }
      }
    }

    &.small-image-left {
      & .image-left {
        grid-column: 1 / 6;
        padding: 75px 0 0 0;

        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-end;

        & .image-container {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;

          & img {
            max-height: calc(100% - 150px);
          }
        }
      }

      & .image-right {
        grid-column: 7 / 13;

        & img {
          object-fit: cover;
        }
      }
    }
  }

  & .image-left {
    & img {
      object-position: left;
    }
  }

  & .image-right {
    & img {
      object-position: right;
    }
  }

  & .image-captions {
    width: 100%;
    max-width: 350px;

    align-self: flex-end;

    & .image-caption {
      display: grid;
      grid-template-columns: 40px auto;
      grid-column-gap: 30px;

      & p {
        margin: 0;
      }

      & .caption-layout {
        font-size: 8px;
        line-height: 18px;
        letter-spacing: 0.15em;
      }

      & .caption-text {
        font-size: 12px;
        line-height: 18px;
        letter-spacing: 0.02em;
      }
    }
  }
`;

const ImageContainer = styled.div`
  position: relative;

  width: 100%;

  height: 100%;

  overflow: hidden;

  & img {
    object-fit: contain;
  }

  & .placeholder {
    width: 600px;
    height: 870px;
  }

  & .grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  &.single-image {
    width: fit-content;
    height: fit-content;
    margin: 0 auto;

    top: 50%;
    transform: translateY(-50%);

    & img {
      max-height: calc(100vh - 150px);
    }
  }
`;

const Placeholder = styled.div`
  width: 100%;
  height: 100%;
  max-width: 1400px;
  max-height: calc(100vh - 150px);
`;

const TwoImagePlaceholder = styled.div`
  width: 100%;
  height: 100%;
  max-width: 700px;
  max-height: calc(100vh - 150px);
`;

export const HomepageGallery = ({ images, opacity, autoplay }) => {
  const slideRef = useRef();

  const [currentSlide, setCurrentSlide] = useState(0);
  const [allGalleryContent, setAllGalleryContent] = useState(null);
  const [totalSlides, setTotalSlides] = useState(null);

  // Measure dimensions of first single image
  const [ref, { x, y, width, height, top, right, bottom, left }] = useMeasure();

  const filteredImages = images.filter(content => {
    if (content.slice_type === "single_image") {
      return content.primary.image.fluid !== null;
    }

    if (content.slice_type === "two_images") {
      return (
        content.primary.image_left.fluid !== null &&
        content.primary.image_right.fluid !== null
      );
    }
  });

  useEffect(() => {
    setTotalSlides(filteredImages.length);
    setAllGalleryContent(filteredImages);
  }, []);

  const [previousIndex, setPreviousIndex] = useState(0);
  const [nextIndex, setNextIndex] = useState(0);

  const firstSingleImageId = filteredImages
    .filter(content => content.slice_type === "single_image")
    .map(content => content.id)[0];

  const galleryContent = filteredImages
    .filter(content => {
      if (content.slice_type === "single_image") {
        return content.primary.image.fluid !== null;
      }

      if (content.slice_type === "two_images") {
        return (
          content.primary.image_left.fluid !== null &&
          content.primary.image_right.fluid !== null
        );
      }
    })
    .map((content, index) => {
      if (content.slice_type === "single_image") {
        if (firstSingleImageId === content.id) {
          return (
            <Slide
              key={`single_homepage_image_container_${content.id}_${index}`}
              className="image-slide max-width"
            >
              <div className="aspect-ratio-box">
                <div className="aspect-ratio-box-inside">
                  <ImageContainer ref={ref} className="single-image">
                    {content.primary.image.fluid !== null && (
                      <img
                        className={`${ImageOrientation(content.primary.image)}`}
                        srcSet={content.primary.image.fluid.srcSetWebp}
                        src={content.primary.image.fluid.srcWebp}
                        alt={content.primary.image.alt}
                        loading="lazy"
                      />
                    )}
                  </ImageContainer>
                </div>
              </div>
            </Slide>
          );
        } else {
          return (
            <Slide
              key={`single_homepage_image_container_${content.id}_${index}`}
              className="image-slide max-width"
            >
              <div className="aspect-ratio-box">
                <div className="aspect-ratio-box-inside">
                  <ImageContainer
                    className="single-image"
                    key={`single_homepage_image_container_${content.id}_${index}`}
                  >
                    {content.primary.image.fluid !== null && (
                      <>
                        {index === currentSlide ||
                        index === currentSlide + 1 ? (
                          <img
                            className={`${ImageOrientation(
                              content.primary.image
                            )}`}
                            srcSet={content.primary.image.fluid.srcSetWebp}
                            src={content.primary.image.fluid.srcWebp}
                            alt={content.primary.image.alt}
                            loading="lazy"
                          />
                        ) : (
                          <Placeholder />
                        )}
                      </>
                    )}
                  </ImageContainer>
                </div>
              </div>
            </Slide>
          );
        }
      }

      if (content.slice_type === "two_images") {
        return (
          <Slide
            key={`single_homepage_image_container_${content.id}_${index}`}
            className={`max-width image-slide two-images-container`}
            width={width}
            height={height}
          >
            <div className="aspect-ratio-box">
              <div className="aspect-ratio-box-inside">
                <div className="flex">
                  <div className="two-images-single-image-container">
                    <ImageContainer>
                      {content.primary.image_left.fluid !== null && (
                        <>
                          {index === currentSlide ||
                          index === currentSlide + 1 ? (
                            <img
                              className={`${ImageOrientation(
                                content.primary.image_left
                              )}`}
                              srcSet={
                                content.primary.image_left.fluid.srcSetWebp
                              }
                              src={content.primary.image_left.fluid.srcWebp}
                              alt={content.primary.image_left.alt}
                              loading="lazy"
                            />
                          ) : (
                            <TwoImagePlaceholder
                              width={
                                content.primary.image_left.dimensions.width
                              }
                              height={
                                content.primary.image_left.dimensions.height
                              }
                            />
                          )}
                        </>
                      )}
                    </ImageContainer>
                  </div>

                  <div className="two-images-single-image-container">
                    <ImageContainer>
                      {content.primary.image_right.fluid !== null && (
                        <>
                          {index === currentSlide ||
                          index === currentSlide + 1 ? (
                            <img
                              className={`${ImageOrientation(
                                content.primary.image_right
                              )}`}
                              srcSet={
                                content.primary.image_right.fluid.srcSetWebp
                              }
                              src={content.primary.image_right.fluid.srcWebp}
                              alt={content.primary.image_right.alt}
                            />
                          ) : (
                            <TwoImagePlaceholder
                              width={
                                content.primary.image_right.dimensions.width
                              }
                              height={
                                content.primary.image_right.dimensions.height
                              }
                            />
                          )}
                        </>
                      )}
                    </ImageContainer>
                  </div>
                </div>
              </div>
            </div>
          </Slide>
        );
      }
    });

  return (
    <GalleryContainer>
      <Fade
        ref={slideRef}
        autoplay={true}
        arrows={false}
        pauseOnHover={false}
        duration={4000}
        transitionDuration={1000}
        easing={`ease`}
        onChange={(previous, next) => {
          setPreviousIndex(previous);
          setNextIndex(next);
          setCurrentSlide(next);
        }}
      >
        {galleryContent}
      </Fade>
    </GalleryContainer>
  );
};

import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Fade } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";

// Utils
import { ImageOrientation } from "../utils/image-orientation";

const GalleryContainer = styled.div`
  position: relative;

  width: 100%;
  height: 100%;
  max-height: 100%;

  padding: 65px 0 55px 0;

  & > div,
  & .react-slideshow-container,
  & .react-slideshow-fade-wrapper,
  & .react-slideshow-fade-images-wrap,
  & .react-slideshow-fade-images-wrap > div,
  & .slider-wrapper,
  & .image-gallery,
  & .slider,
  & .slide {
    height: 100%;
  }

  & .carousel.carousel-slider .control-arrow {
    padding: 0;
    width: 50%;
  }
`;

const ImageContainer = styled.div`
  position: relative;

  width: 100%;
  height: 100%;

  overflow: hidden;

  & .grid {
    display: grid;
    grid-template-columns: 1fr 1fr;

    position: relative;
    width: 100%;
    height: 100%;
  }

  & img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    height: 100%;
    width: 100%;

    object-fit: cover;
  }

  & .two-images-single-image-container {
    position: relative;
  }
`;

export const MobileHomepageGallery = ({ images, opacity, autoplay }) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [allGalleryContent, setAllGalleryContent] = useState([]);
  const [totalSlides, setTotalSlides] = useState(null);

  useEffect(() => {
    setTotalSlides(images.length);
    setAllGalleryContent(images);
  }, []);

  const updateCurrentSlide = index => {
    if (currentSlide !== index) {
      setCurrentSlide(index);
    }
  };

  const galleryContent = images.map((content, index) => {
    if (content.slice_type === "single_image") {
      return (
        <ImageContainer
          key={`single_mobile_homepage_image_container_${content.id}_${index}`}
        >
          {content.primary.image.fluid !== null && (
            <img
              className={ImageOrientation(content.primary.image)}
              srcSet={content.primary.image.fluid.srcSetWebp}
              src={content.primary.image.fluid.srcWebp}
              alt={content.primary.image.alt}
              loading={`lazy`}
            />
          )}
        </ImageContainer>
      );
    }
  });

  return (
    <GalleryContainer>
      <Fade
        autoplay={true}
        arrows={false}
        pauseOnHover={false}
        duration={4000}
        transitionDuration={1000}
        easing={`ease`}
      >
        {galleryContent}
      </Fade>
    </GalleryContainer>
  );
};
